import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const error = System.import("@tdt-global/error");

const data = {
  loaders: {
    homeLoad: `<nav class="placeholder">loading...</nav>`,
  },
  props: {},
  errors: {
    // alternatively:
    error,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

System.import("@tdt-global/styleguide").then(() => {
  layoutEngine.activate();
  start();
});
